import { Account } from '../../common/models'
import auth from '../auth'
import * as proxy from './account-proxy'

export function getAccounts(): Promise<Account[]> {
  return proxy.getAccounts(auth.getAccessToken())
}
export function getAccount(accountId: string): Promise<Account> {
  return proxy.getAccount(auth.getAccessToken(), accountId)
}
