import axios from 'axios'

import { Account } from '../../common/models'
import { logError } from '../logger'

const A_URL = 'https://accounts.cimpress.io/api/v1/accounts'

export const getAccounts = async (
  authorization: string
): Promise<Account[]> => {
  try {
    const response = (
      await axios.get(A_URL, {
        headers: {
          Authorization: `Bearer ${authorization}`,
          'Cache-Control': 'no-cache',
        },
        params: {
          status: 'Active',
          includeAuditInfo: false,
          fields: 'name',
          includeFields: true,
        },
      })
    ).data._embedded.item

    return response.map((account: { accountId: string; name: string }) => {
      return {
        id: account.accountId,
        name: account.name,
      }
    })
  } catch (e) {
    logError('Error when getting accounts', e)
    throw e
  }
}

export const getAccount = async (
  authorization: string,
  accountId: string
): Promise<Account> => {
  try {
    const response = (
      await axios.get(`${A_URL}/${accountId}`, {
        headers: {
          Authorization: `Bearer ${authorization}`,
          'Cache-Control': 'no-cache',
        },
        params: {
          status: 'Active',
          includeAuditInfo: false,
          fields: 'name',
          includeFields: true,
        },
      })
    ).data

    return {
      id: response.accountId,
      name: response.name,
    }
  } catch (e) {
    logError('Error when getting account', e)
    throw e
  }
}
