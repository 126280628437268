import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Switch, useRouteMatch } from 'react-router'
import { Route } from 'react-router-dom'
import { CarrierAccountsContainer } from '../carrier-configuration/components/CarrierAccountsContainer'
import { IncompleteCarrierAccountPage } from '../carrier-configuration/components/IncompleteCarrierAccountPage'
import { NetworkBreadCrumbItem } from '../common/components/RouterWithBreadcrumbs'
import EditLocationPageContainer from '../common/EditLocationPageContainer'
import LocationPermissionsEditor from '../permissions/components/LocationPermissionsEditor'
import { useAppContext } from '../common/AppContext'
import LocationAuditPage from '../audits/components/LocationAuditPage'
import DevelopmentFragment from '../common/components/DevelopmentFragment'
import FragmentLoader from '../common/FragmentLoader'
import LocationPackingFragment from './LocationPackingFragment'
import FulfillerOverview from './components/view-location-page/FulfillerOverviewPage'
import LocationAdvancedOverview from './components/view-location-page/LocationAdvancedOverview'
import LocationShippingFragment from './LocationShippingFragment'

export default function LocationPage() {
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const match = useRouteMatch()

  const detailsTab = () => (
    <main className={`App-content ${containerType} flex-vertical`}>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <FulfillerOverview />
    </main>
  )

  const permissionsTab = () => (
    <main className={`App-content ${containerType} flex-vertical`}>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <LocationPermissionsEditor />
    </main>
  )

  const advancedOverviewTab = () => (
    <main className={`App-content ${containerType} flex-vertical`}>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <LocationAdvancedOverview />
    </main>
  )

  return (
    <Switch>
      <Route path={`${match.url}/calendars`}>
        <FragmentLoader src="https://calendars-ui.qp.cimpress.io/Fragment.js" />
      </Route>
      <Route path={`${match.url}/carrier-accounts`}>
        <main className={`App-content ${containerType} flex-vertical`}>
          <CarrierAccountsContainer />
        </main>
      </Route>
      <Route
        path={`${match.url}/incomplete-carrier-accounts/:incompleteCarrierAccountId`}
        component={IncompleteCarrierAccountPage}
      />
      <Route path={`${match.url}/user-management`} render={permissionsTab} />
      <Route path={`${match.url}/edit`} component={EditLocationPageContainer} />
      <Route path={`${match.url}/packing`}>
        <main className={`App-content ${containerType}`}>
          <LocationPackingFragment />
        </main>
      </Route>
      <Route path={`${match.url}/shipping`}>
        <main className={`App-content ${containerType} flex-vertical`}>
          <LocationShippingFragment />
        </main>
      </Route>
      <Route
        path={`${match.url}/advanced-overview`}
        render={advancedOverviewTab}
      />
      <Route path={`${match.url}/history`}>
        <main className={`App-content ${containerType} flex-vertical`}>
          <LocationAuditPage />
        </main>
      </Route>
      <Route path={`${match.url}/fragments`}>
        <DevelopmentFragment />
      </Route>
      <Route path={`${match.url}`} render={detailsTab} />
    </Switch>
  )
}
