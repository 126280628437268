import axios from 'axios'
import { bearerToken } from '../auth'
import { config } from '../config/config'
import { logError } from '../logger'

export async function getQcmLink(
  logisticLocationId: string,
  fulfillmentLocationId: string
): Promise<string | undefined> {
  try {
    await checkIfQcmConfigurationExists(fulfillmentLocationId)

    return `${config.qcm}/ui/fulfillmentLocations/${logisticLocationId}`
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined
    }
    logError('Unable to retrieve QCM status')
    throw e
  }
}

async function checkIfQcmConfigurationExists(fulfillmentLocationId: string) {
  await axios.get(
    `${config.shippingCalculator}/api/v2/fulfillmentLocations/${fulfillmentLocationId}/latestConfigurations`,
    { headers: { authorization: bearerToken() } }
  )
}
